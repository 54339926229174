<template>
  <div>
    <v-row>
      <!--<v-col cols="3">
        <img
          :src="schoolDetail.logo_url"
          :width="$vuetify.breakpoint.smAndUp ? 140 : 70"
          alt=""
        />
      </v-col>
      <v-col cols="8" :class="$vuetify.breakpoint.smAndUp ? 'px-0 pt-12' : ''">
        <span class="headingSchool">{{ schoolDetail.school_name }}</span>
      </v-col>-->
      <v-col cols="12" sm="10">
        <div
          style="font-size: 24px; color: #2c1963"
          v-if="schoolDetail.original_total_fund_raised_50"
        >
          ${{ schoolDetail.original_total_fund_raised_50.toLocaleString("en-US") }}
          <span
            style="
              color: #2d2d2d;
              font-size: 16px;
              margin-top: auto;
              margin-bottom: auto;
            "
            v-if="schoolDetail.school_goal_50"
            >raised of ${{
              schoolDetail.school_goal_50.toLocaleString("en-US")
            }}
            School goal</span
          >
        </div>
        <v-progress-linear
          :value="schoolDetail.original_fund_raise_percentage"
          color="#4A3290"
          height="25"
          style="border-radius: 20px"
        >
          <strong style="color: white"
            >{{ schoolDetail.original_fund_raise_percentage }}%</strong
          >
        </v-progress-linear>
        <div
          style="font-size: 20px; width: 100%"
          v-if="schoolDetail.total_donor"
        >
          {{ schoolDetail.total_donor.toLocaleString("en-US") }}
          <span style="font-size: 16px; color: #757575">donors</span>
          <div style="float: right">
            {{ getCampaign.campaign_detail.remaining_day }}
            <span style="font-size: 16px; color: #757575">days left</span>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { API_ADMIN_GET_SCHOOL_DETAIL } from "@/constants/APIUrls";
import Axios from "@/api/BaseAxios";
export default {
  name: "SchoolProgressDetail",
  data() {
    return {
      schoolDetail: {},
    };
  },
  computed: {
    ...mapGetters({
      getCampaign: "publicDonationPage/getCampaign",
    }),
    getCampaignDetail() {
      return this.getCampaign;
    },
  },
  watch: {},
  methods: {
    getSchoolDetail() {
      const successHandler = (res) => {
        this.schoolDetail = res.data.school_detail;
      };
      const failureHandler = (res) => {
        console.log(res);
      };
      let formData = {};
      formData["school_id"] = this.getCampaignDetail.student_detail.school_id;
      formData["campaign_id"] = this.getCampaignDetail.campaign_detail.id;
      Axios.request_GET(
        API_ADMIN_GET_SCHOOL_DETAIL,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        false
      );
    },
  },
  mounted() {
      this.getSchoolDetail()
  },
};
</script>
